import React, { useEffect, useState } from 'react';
import { Table, Divider, Spin } from 'antd';
// import 'antd/dist/antd.css';
import { LoadingOutlined } from '@ant-design/icons';
import axios from 'axios';
import { AUTH_TOKEN } from '../../utils/authHeader';
import Navbar from '../../components/Navbar/Navbar';
import Sidebar from '../../components/Sidebar/Sidebar';
import {getAllAllGRN} from "../../Apis/GRN_apis";
import {useSelector} from "react-redux";
import {selectDateRange} from "../../app/slices/dateRangeSlice";
import {getAllASNDetails, getItemDetails} from "../../Apis/Asn_apis";

const List_GRN = () => {
  const [listData, setListData] = useState([]);
  const [loadSet, setLoadSet] = useState(false);
  const [creditNoteData, setCreditNoteData] = useState([]);
    const getDateRange = useSelector(selectDateRange);
const [count , setCount ] = useState(0)

    const setItem = () => {
        const promises = creditNoteData.map(async (doc) => {
                const response = await axios.get(
                    getItemDetails + doc.ASN_Number,
                    {
                        headers: {
                            Authorization: `Bearer ${AUTH_TOKEN()}`,
                        },
                    }
                )
                // doc.items = response.data.map((doc) => doc.item + " - " + doc.qty)
                doc.items = response.data[0].item + " - " + response.data[0].qty
                return doc
            }
        );

        Promise.allSettled(promises)
            .then((results) => {
                console.log('GRN Data:', results);
                setCreditNoteData(results);
            })
            .catch((error) => {
                console.log('Error fetching GRN', error);
                setCreditNoteData([]);
            })
            .finally(() => {
                setLoadSet(false); // Set loading to false after requests complete
            });
    }
  useEffect(() => {
    axios
      .get(
          getAllAllGRN+ "?startDate=" + JSON.parse(getDateRange)[0] + "&endDate=" + JSON.parse(getDateRange)[1],
        {
          headers: {
            Authorization: `Bearer ${AUTH_TOKEN()}`,
          },
        }
      )
      .then((res) => {
        console.log('GET:ALL GRN', res);
          setCreditNoteData(res.data);
      })
      .catch((err) => {
        console.error('GET:ALL-P-Order', err);
      });


  }, []);

    useEffect(() => {
        setCount(count + 1)
        if(count < 2) {
            setItem()
        }
    }, [creditNoteData]);


  const getItemsDetails = async(_id) => {
      console.log(_id)
      return _id
      // axios
      //     .get(
      //         getAllAllGRN+ "?startDate=" + JSON.parse(getDateRange)[0] + "&endDate=" + JSON.parse(getDateRange)[1],
      //         {
      //             headers: {
      //                 Authorization: `Bearer ${AUTH_TOKEN()}`,
      //             },
      //         }
      //     )
      //     .then((res) => {
      //         console.log('GET:ALL GRN', res);
      //         setCreditNoteData(res.data);
      //     })
      //     .catch((err) => {
      //         console.error('GET:ALL-P-Order', err);
      //     });
  }

    const columns = [
        {
            title: 'Purchase Number',
            dataIndex: 'PO_Number',
            key: 'PO_Number',
        },
        {
            title: 'GRN Number',
            dataIndex: 'GRN_Number',
            key: 'GRN_Number',
        },
        {
            title: 'ASN Number',
            dataIndex: 'ASN_Number',
            key: 'ASN_Number',
        },
        {
            title: 'Items',
            dataIndex: 'items',
            key: 'items',
        },
    ];

  return (
    <>
      <main style={{ backgroundColor: '#80' }}>
        {loadSet && <div style={{
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: "absolute",
          width: "100%",
          zIndex: 100,
          background: "#00000099"
        }}>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        </div>}
        <div className="flex" style={{ backgroundColor: '#80' }}>
          <Sidebar />

          <section
            className="dashboard_wrapper"
            style={{ width: '80%', backgroundColor: '#80' }}
          >
            <Navbar title={'GRN / View All GRN List'} />

            <section
              className="lists_table"
              style={{
                background:
                  'white',
                boxShadow:
                  'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px',
                height: '80vh',
                width: '98%',
                margin: '1rem auto',
                padding: '1rem',
              }}
            >
              <h4 style={{ textAlign: 'center' }}> Goods Received Note </h4>
              <Divider />
              <Table dataSource={creditNoteData} columns={columns} />
            </section>
          </section>
        </div>
      </main>
    </>
  );
};

export default List_GRN;
