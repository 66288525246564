import {useEffect, useState} from "react";
import flattenObject from "../hx/FlattenObj";
import axios from "axios";
import ls from "localstorage-slim";
import BaseTable from "./BaseTable";
import { Card, Space, Typography } from 'antd';
import {getAllGRN, getSharedGRN} from "../../Apis/GRN_apis";
import {getEndDSShared} from "../../Apis/DS_apis";

const { Text } = Typography;
const GRN = ({PO_Number, token}) => {
    const [flattenedData, setFlattenedData] = useState([]);
    const [getDebitNoteList, setDebitNoteList] = useState([]);

    const getInfo = () => {
        axios.get(getAllGRN + encodeURIComponent(PO_Number), {
            headers: {
                Authorization: "Bearer " + ls.get("token", { decrypt: true }),
            },
        }).then(res => {
            console.log("res2debit", res);

            // Adding index to the key property
            console.log(res, "grn")
            // const debitNoteListWithKey = res.data.map((item, index) => ({ ...item, key: index.toString() }));
            setDebitNoteList(res.data);
        }).catch((err) => {
            setDebitNoteList([]);
            console.error("res2debit", err);
        })
    }
    const getInfoShared = () => {
        axios.get(getSharedGRN, {
            headers: {
                Authorization: token,
            },
        }).then(res => {
            console.log("res2debit", res);

            // Adding index to the key property
            console.log(res, "grn")
            // const debitNoteListWithKey = res.data.map((item, index) => ({ ...item, key: index.toString() }));
            setDebitNoteList(res.data);
        }).catch((err) => {
            setDebitNoteList([]);
            console.error("res2debit", err);
        })
    }
    useEffect(() => {
        if(token) {
            console.log("token found")
            getInfoShared()
        } else {
            console.log("token not found")
            getInfo()
        }
    }, [PO_Number]);

    return(
        <>
            <h3>GRN</h3>
            <Space direction="vertical" style={{ width: '100%' }} size="large">
                {getDebitNoteList.map((item) => (
                    <><Card key={item._id} title={item.GRN_Number}>
                        <Text>
                            <strong>PO Number:</strong> {item.PO_Number}
                        </Text> <br/>
                        <Text>
                            <strong>ASN Number:</strong> {item.ASN_Number}
                        </Text> <br/>
                        <Text>
                            <strong>Data Schedule ID:</strong> {item.dataScheduleId.join(', ')}
                        </Text> <br/>
                        <Text>
                            <strong>Created At:</strong> {item.createdAt}
                        </Text> <br/>
                    </Card>
                    <hr/></>
                ))}
            </Space>

            {/*{ flattenedData.length > 0 && <BaseTable data={flattenedData} title={"ASN"}/> }*/}
        </>
    )
}

export default GRN;

