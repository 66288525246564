import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    isOpen: false,
    type: null,
    message: '',
    redirectRoute: null,
};
const modalSlice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        openModal: (state, action) => {
            state.isOpen = true;
            state.type = action.payload.type;
            state.message = action.payload.message;
            state.redirectRoute = action.payload.redirectRoute;
        },
        closeModal: (state) => {
            state.isOpen = false;
            state.type = null;
            state.message = '';
            state.redirectRoute = null;
        },
    },
});

export const { openModal, closeModal } = modalSlice.actions;

export default modalSlice.reducer;
