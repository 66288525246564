import React, { useEffect, useState } from "react";
import { getEndDSByPo, getRawDSByPo } from "../../Apis/DS_apis";
import ls from "localstorage-slim";
import axios from "axios";
import { Table, Divider, Button } from "antd";
import { useNavigate } from "react-router-dom";
import Create_ASN from "../ASN/createAsn";
import { DeleteFilled, DownloadOutlined, EditFilled, EyeOutlined } from "@ant-design/icons";

const ViewEndDS = ({ PO_Number, DSLIST = [] }) => {
    const [getRawDsList, setRawDsList] = useState([]);
    const [getTableData, setTableData] = useState([]);
    const navigate = useNavigate()

    useEffect(() => {
        if(DSLIST.length <= 0) {
            axios.get(getEndDSByPo + encodeURIComponent(PO_Number), {
                headers: {
                    Authorization: "Bearer " + ls.get("token", { decrypt: true }),
                },
            }).then(res => {
                console.log("res22end", res);
                setRawDsList(res.data);
            }).catch((err) => {
                console.error("pubgmobile", err);
            })
        } else {
            setRawDsList(DSLIST)
        }
    }, [PO_Number])

    const handleEdit = (editedData) => {
        // Make a PUT request to your API endpoint with the updated data
        fetch('your_put_api_endpoint', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(editedData),
        })
            .then(response => response.json())
            .then(data => {
                // Handle response or update state if needed
                console.log('Edit successful:', data);
            })
            .catch(error => {
                // Handle error
                console.error('Error editing data:', error);
            });
    };
    useEffect(() => {
        // Assuming getRawDsList is a state variable holding your data
        const formattedData = [];

        getRawDsList.forEach((rawDs) => {
            if (rawDs.items.some(item => item.fulfillment.lotwise.length > 0)) {
                // If lotwise data exists, create a table for it
                const lotwiseData = [];
                rawDs.items.forEach((rawmaterial) => {
                    rawmaterial.fulfillment.lotwise.forEach((lot) => {
                        const date = new Date(lot.delivery_date);
                        const formattedDate = `${('0' + date.getDate()).slice(-2)}-${('0' + (date.getMonth() + 1)).slice(-2)}-${date.getFullYear()} ${('0' + date.getHours()).slice(-2)}:${('0' + date.getMinutes()).slice(-2)}`;

                        lotwiseData.push({
                            key: lot._id,
                            itemName: rawmaterial.item,
                            lot_code: lot.lot_code,
                            packing_size: lot.packing_size,
                            lot_number_from: lot.lot_number_from,
                            lot_number_end: lot.lot_number_end,
                            price: lot.price,
                            quantity: lot.quantity,
                            totalAmount: lot.totalAmount,
                            delivery_date: formattedDate,
                            op: (
                                <Button type="link"
                                //  onClick={() => handleEdit(item)}
                                >
                                    <EditFilled onClick={(e) => {
                                        e.preventDefault()
                                        console.log(lot, rawmaterial, "lotttttt")
                                        navigate("/ds/end-material-delivery-schedule", { state: { PO_Number: rawDs._id } })
                                    }} />
                                </Button>
                            ),
                        });
                    });
                });

                formattedData.push({
                    type: 'lotwise',
                    data: lotwiseData,
                    title: `Lotwise Table - ${rawDs.PO_Number}`
                });
            }

            if (rawDs.items.some(item => item.fulfillment.itemwise.length > 0)) {
                // If itemwise data exists, create a table for it
                const itemwiseData = [];
                rawDs.items.forEach((rawmaterial) => {
                    rawmaterial.fulfillment.itemwise.forEach((item) => {
                        const date = new Date(item.delivery_date);
                        const formattedDate = `${('0' + date.getDate()).slice(-2)}-${('0' + (date.getMonth() + 1)).slice(-2)}-${date.getFullYear()} ${('0' + date.getHours()).slice(-2)}:${('0' + date.getMinutes()).slice(-2)}`;

                        itemwiseData.push({
                            key: item._id,
                            itemNumber: item.itemNumber,
                            itemName: rawmaterial.item,
                            price: item.price,
                            quantity: item.quantity,
                            totalAmount: item.totalAmount,
                            delivery_date: formattedDate,
                        });
                    });
                });

                formattedData.push({
                    type: 'itemwise',
                    data: itemwiseData,
                    title: `Itemwise Table - ${rawDs.PO_Number}`
                });
            }
        });

        setTableData(formattedData);
    }, [getRawDsList]);
    return (
        <>
            {/*{PO_Number}*/}
            <h4 style={{ textAlign: 'center' }}>{DSLIST.length > 0 ? PO_Number : ""}</h4>
            <Divider />

            {getTableData.map((table, index) => (
                <div key={index}>
                    <h5>{table.title}</h5>
                    <Table
                        style={{ marginTop: "1rem" }}
                        dataSource={table.data}
                        columns={table.type === 'lotwise' ? lotwiseColumns : itemwiseColumns}
                    />
                </div>
            ))}

        </>
    )
}

const lotwiseColumns = [
    {
        title: 'Lot Code',
        dataIndex: 'lot_code',
        key: 'lot_code',
    },
    {
        title: 'itemName',
        dataIndex: 'itemName',
        key: 'itemName',
    },
    {
        title: 'Packing Size',
        dataIndex: 'packing_size',
        key: 'packing_size',
    },
    {
        title: 'lot_number_from',
        dataIndex: 'lot_number_from',
        key: 'lot_number_from',
    },
    {
        title: 'Lot number_end',
        dataIndex: 'lot_number_end',
        key: 'lot_number_end',
    },
    {
        title: 'price',
        dataIndex: 'price',
        key: 'price',
    },
    {
        title: 'quantity',
        dataIndex: 'quantity',
        key: 'quantity',
    },
    {
        title: 'totalAmount',
        dataIndex: 'totalAmount',
        key: 'totalAmount',
    },
    {
        title: 'ScheduleDeliveryDate',
        dataIndex: 'delivery_date',
        key: 'delivery_date',
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
    },
    // {
    //     title: "",
    //     dataIndex: "op",
    //     key: "op",
    // },
];

const itemwiseColumns = [
    {
        title: 'Item Number',
        dataIndex: 'itemNumber',
        key: 'itemNumber',
    },
    {
        title: 'Item Name',
        dataIndex: 'itemName',
        key: 'itemName',
    },
    {
        title: 'Price',
        dataIndex: 'price',
        key: 'price',
    },
    {
        title: 'Quantity',
        dataIndex: 'quantity',
        key: 'quantity',
    },
    {
        title: 'Total Amount',
        dataIndex: 'totalAmount',
        key: 'totalAmount',
    },
    {
        title: 'Delivery Date',
        dataIndex: 'delivery_date',
        key: 'delivery_date',
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
    },
    // {
    //     title: "",
    //     dataIndex: "op",
    //     key: "op",
    // },
];


export default ViewEndDS;
