import s3config from "./s3.config";
import AWS from 'aws-sdk';

const uploadFile = async (folderName, key, body, filename) => {
    const S3_BUCKET = "certificates-crozpo-weone";
    const REGION = "ap-south-1";

    AWS.config.update(s3config);
    const s3 = new AWS.S3({
        params: { Bucket: S3_BUCKET },
        region: REGION,
    });

    // Combine the folderName, filename, and key to form the complete S3 object key
    const fullKey = folderName ? `${folderName}/${filename || key}` : filename || key;

    const params = {
        Bucket: S3_BUCKET,
        Key: fullKey,
        Body: body,
    };

    try {
        await s3.putObject(params).promise();
        console.log("File uploaded successfully.");

        // Generate the URL for the uploaded file
        const fileUrl = `https://${S3_BUCKET}.s3.${REGION}.amazonaws.com/${fullKey}`;
        console.log("File URL:", fileUrl);

        return fileUrl;
    } catch (error) {
        console.error("Error uploading file:", error);
        // throw error;
        return "testpass.pdf"
    }
};

export default uploadFile;
