import BaseTable from "./BaseTable";
import {useMemo} from "react";

const RawMaterial = ({data}) => {
    const printData = useMemo(() => {
        return data.map((d) => {
            return {Name: d.itemName,Quantity: d.itemQuantity};
        });
    }, [data]);
    return(
        <>
            <h5>Raw Materials</h5>
            <BaseTable data={printData} title={""}/>
        </>
    )
}

export default RawMaterial
