import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {AUTH_TOKEN} from "../../utils/authHeader";
import {Spin} from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import ls from "localstorage-slim";

const SharePO = () => {
    const {id} = useParams();
    const [apiData, setGetApiData] = useState(null);
    const navigate = useNavigate()
    const fetchData = () => {
        axios
            .get(
                `https://vo1urbmb5k.execute-api.ap-south-1.amazonaws.com/dev/api/purchaseOrder/getSharedPOInfo`,
                {
                    headers: {
                        Authorization: id,
                    },
                }
            )
            .then((res) => {
                console.log("GET:-Particular-PO", res);
                setGetApiData(res.data);
            })
            .catch((err) => {
                console.log("GET:-Particular-PO", err);
            });
    };

    useEffect(() => {
        fetchData();
    }, []);


    useEffect(() => {
        console.log(apiData, "testing one")
        apiData !== null && navigate("/podownload", { state: { data: apiData, print: true, token: id } });
    }, [apiData]);

    return <div style={{
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: "absolute",
        width: "100%",
        zIndex: 100,
        background: "#00000099"
    }}>
        <Spin indicator={<LoadingOutlined style={{fontSize: 24}} spin/>}/>
    </div>
}


export default SharePO;
