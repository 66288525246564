const baseURL = "https://22gxiwdppa.execute-api.ap-south-1.amazonaws.com/dev/api/ds/"

export const createRawDS = baseURL + "rawCreateDS";
export const createEndDS = baseURL + "createDeliverySchedules";
export const getRemainingQTY = baseURL + "getRemainingQTY/";
export const getRAWRemainingQTY = baseURL + "rd/getRemainingQTY/";
export const getRawDSByPo = baseURL + "getByPO/";
export const getEndDSByPo = baseURL + "getDeliverySchedule/";
export const getEndDSShared = baseURL + "getSharedDeliverySchedule";
export const getRAWDSShared = baseURL + "getSharedRAWDeliverySchedule";
export const updateEndDSByPo = baseURL + "updateDeliverySchedule";


export const getAllDeliverySchedule = baseURL + "getAllDeliverySchedule"
