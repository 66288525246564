import React, {useEffect, useState} from "react";
import flattenObject from "../hx/FlattenObj";
import axios from "axios";
import {getAllASN} from "../../Apis/Asn_apis";
import ls from "localstorage-slim";
import BaseTable from "./BaseTable";
import {getEndDSByPo, getEndDSShared} from "../../Apis/DS_apis";
import {Table} from "antd";

const ENDDS = ({PO_Number,token}) => {
    const [flattenedData, setFlattenedData] = useState([]);
    const [getDebitNoteList, setDebitNoteList] = useState([]);


    const getInfo = () => {
        axios.get(getEndDSByPo + encodeURIComponent(PO_Number), {
            headers: {
                Authorization: "Bearer " + ls.get("token", { decrypt: true }),
            },
        }).then(res => {
            console.log("res2debit", res);

            // Adding index to the key property
            console.log(res, "oneasdjfhakjshdfja")
            // const debitNoteListWithKey = res.data.map((item, index) => ({ ...item, key: index.toString() }));
            setDebitNoteList(res.data);
        }).catch((err) => {
            setDebitNoteList([]);
            console.error("res2debit", err);
        })
    }
    const getInfoShared = () => {
        axios.get(getEndDSShared, {
            headers: {
                Authorization: token,
            },
        }).then(res => {
            console.log("res2debit", res);

            // Adding index to the key property
            console.log(res, "oneasdjfhakjshdfja")
            // const debitNoteListWithKey = res.data.map((item, index) => ({ ...item, key: index.toString() }));
            setDebitNoteList(res.data);
        }).catch((err) => {
            setDebitNoteList([]);
            console.error("res2debit", err);
        })
    }
    useEffect(() => {
        if(token) {
            console.log("token found")
            getInfoShared()
        } else {
            console.log("token not found")
            getInfo()
        }
    }, [PO_Number]);

    useEffect(() => {
        // Flatten the object within the array

        // const flattened = getDebitNoteList.map(item => {
        //     return flattenObject(item)
        // });
        // setFlattenedData(flattened);
        // console.log(flattened, "oneasdjfhakjshdfjafla")
        const formattedData = [];

        getDebitNoteList.forEach((rawDs) => {
            if (rawDs.items.some(item => item.fulfillment.lotwise.length > 0)) {
                // If lotwise data exists, create a table for it
                const lotwiseData = [];
                rawDs.items.forEach((rawmaterial) => {
                    rawmaterial.fulfillment.lotwise.forEach((lot) => {
                        lotwiseData.push({
                            "Lot ID": lot._id,
                            "Item Name": rawmaterial.item,
                            "Lot Code": lot.lot_code,
                            "Packing Size": lot.packing_size,
                            "Lot Number From": lot.lot_number_from,
                            "Lot Number End": lot.lot_number_end,
                            "Price": lot.price,
                            "Quantity": lot.quantity,
                            "Total Amount": lot.totalAmount,
                            "Delivery Date": lot.delivery_date
                        });
                    });
                });

                formattedData.push({
                    type: 'lotwise',
                    data: lotwiseData,
                    title: `Lotwise Table`
                });
            }

            if (rawDs.items.some(item => item.fulfillment.itemwise.length > 0)) {
                // If itemwise data exists, create a table for it
                const itemwiseData = [];
                rawDs.items.forEach((rawmaterial) => {
                    rawmaterial.fulfillment.itemwise.forEach((item) => {
                        itemwiseData.push({
                            "Item ID": item._id,
                            "Item Number": item.itemNumber,
                            "Item Name": rawmaterial.item,
                            "Price": item.price,
                            "Quantity": item.quantity,
                            "Total Amount": item.totalAmount,
                            "Delivery Date": item.delivery_date,
                        });
                    });
                });

                formattedData.push({
                    type: 'itemwise',
                    data: itemwiseData,
                    title: `Itemwise Table`
                });
            }
        });

        setFlattenedData(formattedData);
    }, [getDebitNoteList]);

    useEffect(() => {
        console.log(flattenedData, "oneasdjfhakjshdfjaflasss")
    }, [flattenedData]);
    return(
        <>
            <h4>END Delivery Schedule</h4>
            {flattenedData.map((table, index) => (
                <div key={index}>

                    <BaseTable data={table.data} title={""}/>
                </div>
            ))}

            {/*{ flattenedData.length > 0 && <BaseTable data={flattenedData} title={"END Delivery Schedule"}/> }*/}
        </>
    )
}

export default ENDDS;

