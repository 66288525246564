import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Sidebar from "../../components/Sidebar/Sidebar";
import { Form, Input, DatePicker, Button, notification, Radio, Tooltip, Select,Divider} from "antd";
import { useLocation } from "react-router-dom";
import { RetweetOutlined } from "@ant-design/icons";
import axios from "axios";
import ls from "localstorage-slim";
import { getAllASN } from "../../Apis/Asn_apis";
import { createCreditNote } from "../../Apis/CreditNote_apis";
import {useDispatch} from "react-redux";
import {openModal} from "../../app/slices/modelSlice";

function CreditNote() {
  const [listType, setListType] = useState("lotwise"); // Default to lotwise
  const [poList, setPoList] = useState([]);
  const [asnList, setAsnList] = useState([]);
  const [listData, setListData] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    PO_Number: "",
    ASN_Number: "",
    creditNoteNumber: "",
    description: "",
    amount: ""
  });

  const location = useLocation();
const dispatch = useDispatch();
  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type, message, description) => {
    api[type]({
      message,
      description
    });
  };
  const [form] = Form.useForm();
  useEffect(() => {
    reGenerateDebitNoteNumber()
    if (location.state && location.state.viewId) {
      // Update the PO_Number in the form data
      console.log("location.state.viewId", location.state.viewId)
      setFormData(prevState => ({
        ...prevState,
        PO_Number: location.state.viewId,
      }));
      form.setFieldValue("PO_Number", location.state.viewId)
      form.setFieldsValue({
        creditNoteNumber: `CN${Date.now()}`,
      }); const element = document.getElementById('po-list');
      console.log(element)
      if (element) {
        element.disabled = true;
      }
    }
    axios
      .get(
        "https://vo1urbmb5k.execute-api.ap-south-1.amazonaws.com/dev/api/purchaseOrder/getAllPurchaseOrders",
        {
          headers: {
            Authorization:
              "Bearer " + ls.get("token", { decrypt: true }),
          },
        }
      )
      .then((res) => {
        console.log("GET:ALL-P-Order", res);
        setListData(res.data.data);
        if (res.data.data.length !== 0) {
          const dropdownData = res.data.data.map((po) => ({
            value: po.PO_Number,
            label: `${po.buyerID}---${po.PO_Number}---${po.currency}---${po.deliveryAddress}---${po.documentDate}---${po.totalAmount}---${po.vendorID}`,
          }));
          setPoList(dropdownData);
        }
        // openNotificationWithIcon("success", "SUBMITTED Successfully", "Credit Note Created for ASN")
      })
      .catch((err) => {
        console.log("GET:ALL-P-Order", err);
        // openNotificationWithIcon("error", "could not generate credit note", "could not Credit Note Generated.")
      });
  }, [form])

  useEffect(() => {
    if (formData.PO_Number === "") {
    }
    else {
      axios.get(
        getAllASN + encodeURIComponent(formData.PO_Number),
        {
          headers: {
            Authorization:
              "Bearer " + ls.get("token", { decrypt: true }),
          },
        }).then(res => {
          console.log("resAsn", res);
          if (res.data.length !== 0) {
            const dropdownData = res.data.map((po) => ({
              value: po.ASN_Number,
              label: `${po.ASN_Number}---${po.PO_Number}---${po.eWayBillNo}---${po.shippingDetails.shippingAddress}`,
            }));
            setAsnList(dropdownData);
          }

          // Optionally, you can set default values for specific fields after submission
          form.setFieldsValue({
            creditNoteNumber: `CN${Date.now()}`,
          });
        }).catch(err => {
          console.error("errere ", err)
        })
    }
  }, [formData.PO_Number])

  useEffect(() => {
    console.log(asnList)
  }, [asnList])

  const reGenerateDebitNoteNumber = (e) => {
    form.setFieldValue("creditNoteNumber", `CN${Date.now()}`)
    setFormData((prevData) => {
      return {
        ...prevData,
        ["creditNoteNumber"]: `CN${Date.now()}`,
      };
    });
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());


  // Function to update formData with the form field changes
  const handleFormChange = (fieldName, value) => {
    setFormData((prevData) => {
      return {
        ...prevData,
        [fieldName]: value,
      };
    });
  };

  // Handle form submission
  const handleSubmit = async () => {
    try {
      setLoading(true);
      // Construct fulfillment object based on the selected listType

      // Handle the form data as needed
      console.log('Form submitted locally:', formData);

      await axios.post(createCreditNote, formData, {
        headers: {
          Authorization:
            "Bearer " + ls.get("token", { decrypt: true }),
        },
      }).then(res => {
        console.log("ccn", res)
        const isPOList = location.state && location.state.viewId;
        const redirectRoute = isPOList ? '/p-order/lists' : '/list/creditnote';
        const viewName = isPOList ? 'PO List' : 'Creditnotes List';
        let message = `Creditnote Generated for po: ${formData.PO_Number}. You will be redirected to ${viewName}.`;


        dispatch(openModal({
          type: 'success',
          message: message,
          redirectRoute: redirectRoute,
        }))
        // openNotificationWithIcon("success", "SUBMITTED Successfully", "Credit Note Created for ASN")
      }).catch(err => {
        openNotificationWithIcon("error", "could not generate credit note", "could not Generate Credit Note.")
        console.error("errccn", err)
      })
      // form.resetFields();
      //
      // // Optionally, you can reset the form fields after successful submission
      // setFormData({
      //   PO_Number: "",
      //   ASN_Number: "",
      //   amount: "",
      //   description: ""
      // });
      // reGenerateDebitNoteNumber()

      // You can add additional logic here, such as navigation or displaying a success message.
    } catch (error) {
      console.error('Error submitting form:', error);
      // Handle error state if the submission fails
    }finally {
      setLoading(false);
    }
  };


  return (
    <>
      {contextHolder}
      <main style={{ backgroundColor: "#80" }}>
        <div className="flex" style={{ backgroundColor: "#80" }}>
          <Sidebar />

          <section
            className="dashboard_wrapper"
            style={{ width: "80%", backgroundColor: "#80" }}
          >
            <Navbar title={'Credit Note / Create Credit note'} />

            <section className="lists_table" style={{ background: "white", boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px", height: "80vh", width: "98%", margin: "1rem auto", padding: "1rem" }}>
              <h4 style={{ textAlign: 'center' }}> Credit Note </h4>
              <Divider />
              <Form
                form={form}
                labelCol={{
                  span: 4,
                }}
                wrapperCol={{
                  span: 14,
                }}
                layout="horizontal"
                initialValues={{
                  size: "default",
                }}
                onFinish={handleSubmit}
              >
                <Form.Item
                  label="CreditNote Number"
                  name="creditNoteNumber"
                  rules={[
                    { required: true, message: 'Please enter CreditNote Number..!' },
                  ]}
                  shouldUpdate
                >
                  <div style={{ display: "flex" }}>
                    <Input
                      type="text"
                      value={formData.creditNoteNumber}
                      onChange={(e) =>
                        setFormData((prevData) => ({
                          ...prevData,
                          creditNoteNumber: e.target.value,
                        }))
                      }
                    />
                    <Tooltip placement="top" title={"Regenerate CN number"}>
                      <Button onClick={reGenerateDebitNoteNumber}>
                        <RetweetOutlined />
                      </Button>
                    </Tooltip>
                  </div>
                </Form.Item>


                {/* PO Number Start */}

                <Form.Item label="Purchase Order:" name="PO_Number"
                  htmlFor="po-list" style={{ marginLeft: "1rem" }}
                  rules={[
                    {
                      required: !disabled,  // Make the rule required only when not disabled
                      message: 'Please enter PO Number..!',
                      validator: (_, value) => {
                        if (!disabled && !value) {
                          return Promise.reject('Please enter PO Number..!');
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}>
                  {disabled ? (
                    <Select
                      filterOption={filterOption}
                      id="po-list"
                      showSearch
                      defaultValue="Search By PONumber"
                      style={{
                        marginLeft: "1rem",
                        marginTop: "1rem"
                      }}
                      value={formData.PO_Number}
                      disabled
                    />
                  ) : (
                    <Select
                      filterOption={filterOption}
                      id="po-list"
                      showSearch
                      placeholder="Search Buyer Name"
                      style={{
                        marginLeft: "1rem",
                        marginTop: "1rem"
                      }}
                      value={formData.PO_Number}
                      onChange={(e) => {
                        console.log(e)
                        form.setFieldValue("PO_Number", e)
                        setFormData(prevState => ({
                          ...prevState,
                          PO_Number: e,
                        }));
                      }}
                      options={poList}
                    />
                  )}
                </Form.Item>
                {/* PO Number End */}

                {/* ASN_Number Start */}
                <Form.Item label="ASN Number" name="ASN_Number">
                  <Select
                    filterOption={filterOption}
                    id="po-list"
                    showSearch
                    defaultValue={formData.ASN_Number || "Select ASN Number"}
                    value={formData.ASN_Number}
                    onChange={(e) => {
                      console.log(e)
                      setFormData(prevState => ({
                        ...prevState,
                        ASN_Number: e,
                      }));
                    }}
                    options={asnList}
                  />
                </Form.Item>
                {/* ASN_Number End */}

                {/* Amount Start */}
                <Form.Item label="Amount" name="amount"
                  rules={[
                    { required: true, message: 'Please enter Amount..!' },
                  ]}>
                  <Input
                    type="text"
                    value={formData.amount}
                    onChange={(e) => handleFormChange('amount', e.target.value)}
                  />
                </Form.Item>
                {/* Amount End */}

                {/* Contact Person Name start */}
                <Form.Item label="description" name="description">
                  <Input
                    type="text"
                    value={formData.description}
                    onChange={(e) => handleFormChange('description', e.target.value)}
                  />
                </Form.Item>
                {/* Contact Person Name End */}

                {/* Submit button Start */}
                <div
                  className="flex"
                  style={{ justifyContent: "center", marginTop: "2rem" }}
                >
                  <Button type="primary" htmlType="submit" loading={loading}>Create Credit Note</Button>
                </div>
                {/* Submit Button end */}
              </Form>

            </section>
          </section>
        </div>
      </main>
    </>
  );
}

export default CreditNote;
