import React, {useEffect, useState} from 'react';
// import './tabul.css'
import flattenObject from "../hx/FlattenObj";
import BaseTable from "./BaseTable";
import ASN from "./ASN";
import ENDDS from "./ENDDS";
import {Button, Descriptions} from "antd"
import { useLocation } from 'react-router-dom';
import GRN from "./GRN";
import {DownloadOutlined} from "@ant-design/icons";
import RawMaterial from "./RawMaterial";
import RAWDS from "./RAWDS";

const PurchaseOrderPDF = () => {
    const location = useLocation();
    const {
        buyerID,
        vendorID,
        documentDate,
        PO_Number,
        currency,
        totalAmount,
        items,
        rawmaterial,
        status,
        deliveryAddress,
    } = location.state.data;
    console.log(location.state.data)
    const [flattenedData, setFlattenedData] = useState([]);


    const humanReadableKeys = [
        { oldKey: '_id', newKey: 'ID' },
        { oldKey: 'status', newKey: 'Status' },
        { oldKey: 'itemCategory', newKey: 'Item Category' },
        { oldKey: 'material', newKey: 'Item' },
        { oldKey: 'quantity', newKey: 'Quantity' },
        { oldKey: 'plant', newKey: 'Plant' },
        { oldKey: 'price', newKey: 'Price' },
        { oldKey: 'GST', newKey: 'GST' },
        { oldKey: 'totalAmount', newKey: 'Total Amount' },
        { oldKey: 'accountAssignmentCategory', newKey: 'Account Assignment Category' },
        { oldKey: 'unitOfMeasurement', newKey: 'Unit of Measurement' },
        { oldKey: 'HSNCode', newKey: 'HSN Code' },
    ];
    useEffect(() => {
        // Flatten the object within the array
        console.log(items)
        const flattened = items.map(item => {
            item.status = Object.keys(item.status).map(key => `${key}: ${item.status[key]}`).join('\n')
            console.log(item)
            return flattenObject(item)
        });
        console.log("oneasdjfhakjshdfja", flattened)
        const flattened1 = flattened.map(item => {
            const convertedItem = {};
            for (const key in item) {
                if(key === "_id" || key === "status") {
                    continue
                }
                const newKeyObj = humanReadableKeys.find(obj => obj.oldKey === key);
                const newKey = newKeyObj ? newKeyObj.newKey : key;
                convertedItem[newKey] = item[key];
            }
            return convertedItem;
        })
        setFlattenedData(flattened1);
    }, []);

    useEffect(() => {
        setTimeout(() => {
            !location.state.print && window.print();
        }, 1000)
    }, []);

    const styles = {
        '@media print and (width: 21cm) and (height: 29.7cm)': {
            '@page': {
                margin: '3cm',
            },
        },
        '@media print': {
            '.noPrint, .noPrint *': { // Added a colon to separate key-value pairs
                display: 'none !important', // Enclosed the value in quotes
            },
        },
    };

    return (
        <div style={styles}>
            <div className="purchase-order" id={"report"} style={{
                width: "11.7in", background: "white",

            }}>

                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <h2>Purchase Order Details </h2>
                    <Button value={"download PDF"} className={".noPrint"} onClick={() => {
                        window.print()
                    }}>
                        <DownloadOutlined/> Download PO
                    </Button>
                </div>
                <Descriptions title="" bordered>
                    <Descriptions.Item label="PO Number">{PO_Number}</Descriptions.Item>
                    <Descriptions.Item label="Currency">{currency}</Descriptions.Item>
                    <Descriptions.Item label="Total Amount">{totalAmount}</Descriptions.Item>
                    <Descriptions.Item label="Delivery Address">{deliveryAddress}</Descriptions.Item>
                    <Descriptions.Item label="Buyer">{buyerID}</Descriptions.Item>
                    <Descriptions.Item label="Vendor">{vendorID}</Descriptions.Item>
                    <Descriptions.Item label="Document Date">{documentDate}</Descriptions.Item>
                </Descriptions>

                <h5>Items</h5>
                <BaseTable data={flattenedData} title={""}/>
                <hr/>
                {rawmaterial.rawStatus ? <RawMaterial data={rawmaterial.rawItems}/> :
                    <h4>Raw Materials not included</h4>}
                <hr/>
                <RAWDS PO_Number={PO_Number} token={location.state.token}/>
                <hr/>
                <ENDDS PO_Number={PO_Number} token={location.state.token}/>
                <hr/>
                <ASN PO_Number={PO_Number} token={location.state.token}/>
                <hr/>
                <GRN PO_Number={PO_Number} token={location.state.token}/>
                <hr/>
            </div>
        </div>
    );
};

export default PurchaseOrderPDF;
