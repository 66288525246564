import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Sidebar from "../../components/Sidebar/Sidebar";
import { Form, Input, notification, Button, Tooltip, Select,Divider } from "antd";
import { useLocation } from "react-router-dom";
import { RetweetOutlined } from "@ant-design/icons";
import axios from "axios";
import ls from "localstorage-slim";
import { getAllASN } from "../../Apis/Asn_apis";
import { createDebitNote } from "../../Apis/DebitNote_apis";
import { getAllGRN } from "../../Apis/GRN_apis";
import {openModal} from "../../app/slices/modelSlice";
import {useDispatch} from "react-redux";

function DebitNote() {
  const [listType, setListType] = useState("lotwise"); // Default to lotwise
  const [poList, setPoList] = useState([]);
  const [asnList, setAsnList] = useState([]);
  const [listData, setListData] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    PO_Number: "",
    GRN_Number: "",
    debitNoteNumber: "",
    description: "",
    amount: ""
  });
  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type, message, description) => {
    api[type]({
      message,
      description
    });
  };
  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    reGenerateDebitNoteNumber()
    if (location.state && location.state.viewId) {
      // Update the PO_Number in the form data
      console.log("location.state.viewId", location.state.viewId)
      setFormData(prevState => ({
        ...prevState,
        PO_Number: location.state.viewId,
      }));
      form.setFieldValue("PO_Number", location.state.viewId)
      setDisabled(true)
      const element = document.getElementById('po-list');
      console.log(element)
      if (element) {
        element.disabled = true;
      }
    }
    axios
      .get(
        "https://vo1urbmb5k.execute-api.ap-south-1.amazonaws.com/dev/api/purchaseOrder/getAllPurchaseOrders",
        {
          headers: {
            Authorization:
              "Bearer " + ls.get("token", { decrypt: true }),
          },
        }
      )
      .then((res) => {
        console.log("GET:ALL-P-Order", res);
        setListData(res.data.data);
        if (res.data.data.length !== 0) {
          const dropdownData = res.data.data.map((po) => ({
            value: po.PO_Number,
            label: `${po.buyerID}---${po.PO_Number}---${po.currency}---${po.deliveryAddress}---${po.documentDate}---${po.totalAmount}---${po.vendorID}`,
          }));
          setPoList(dropdownData);
        }
      })
      .catch((err) => {
        console.log("GET:ALL-P-Order", err);
      });
  }, [])


  useEffect(() => {
    if (formData.PO_Number === "") {
    }
    axios.get(
      getAllGRN + encodeURIComponent(formData.PO_Number),
      {
        headers: {
          Authorization:
            "Bearer " + ls.get("token", { decrypt: true }),
        },
      }).then(res => {
        console.log("resAsn", res);
        if (res.data.length !== 0) {
          const dropdownData = res.data.map((po) => ({
            value: po.GRN_Number,
            label: `${po.GRN_Number}---${po.PO_Number}---${po.ASN_Number}`,
          }));
          setAsnList(dropdownData);
        }
      }).catch(err => {
        console.error("errere ", err)
      })
  }, [formData.PO_Number])

  useEffect(() => {
    console.log(asnList)
  }, [asnList])

  const reGenerateDebitNoteNumber = (e) => {
    form.setFieldValue("debitNoteNumber", `DN${Date.now()}`)
    setFormData((prevData) => {
      return {
        ...prevData,
        ["debitNoteNumber"]: `DN${Date.now()}`,
      };
    });
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());


  // Function to update formData with the form field changes
  const handleFormChange = (fieldName, value) => {
    setFormData((prevData) => {
      return {
        ...prevData,
        [fieldName]: value,
      };
    });
  };

  // Handle form submission
  const handleSubmit = async (value) => {
    try {
      setLoading(true);
      console.log(value)
      // Construct fulfillment object based on the selected listType
      // Handle the form data as needed
      console.log('Form submitted locally:', formData);

      await axios.post(createDebitNote, formData, {
        headers: {
          Authorization:
            "Bearer " + ls.get("token", { decrypt: true }),
        },
      }).then(res => {
        console.log("ccn", res)
        const isPOList = location.state && location.state.viewId;
        const redirectRoute = isPOList ? '/p-order/lists' : '/list/debitnote';
        const viewName = isPOList ? 'PO List' : 'Debitnote List';
        let message = `Debitnote Generated for po: ${formData.PO_Number}. You will be redirected to ${viewName}.`;


        dispatch(openModal({
          type: 'success',
          message: message,
          redirectRoute: redirectRoute,
        }))
        // openNotificationWithIcon("success", "SUBMITTED Successfully", "DEBIT NOTE saved in database for PO: " + res.data.PO_Number)
        console.log("dn", res)
      }).catch(err => {
        openNotificationWithIcon("error", "Debit note not submimtteed", "DEBIT NOTE saved in database for PO: " + formData.PO_Number)

        console.error("errdn", err)
      })
      reGenerateDebitNoteNumber()
      // Reset the form fields using Ant Design useForm hook
      form.resetFields();
      // Optionally, you can reset the form fields after successful submission
      setFormData({
        PO_Number: "",
        GRN_Number: "",
        debitNoteNumber: `DN${Date.now()}`,
        description: "",
        amount: ""
      });


      // You can add additional logic here, such as navigation or displaying a success message.
    } catch (error) {
      console.error('Error submitting form:', error);
      // Handle error state if the submission fails
    }finally{
      setLoading(false);
    }
  };


  return (
    <>
      {contextHolder}
      <main style={{ backgroundColor: "#80" }}>
        <div className="flex" style={{ backgroundColor: "#80" }}>
          <Sidebar />

          <section
            className="dashboard_wrapper"
            style={{ width: "80%", backgroundColor: "#80" }}
          >
            <Navbar title={'Debit Note / Create Debit note'} />

            <section className="lists_table" style={{ background: "white", boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px", height: "80vh", width: "98%", margin: "1rem auto", padding: "1rem" }}>
              <Form
                form={form}
                labelCol={{
                  span: 4,
                }}
                wrapperCol={{
                  span: 14,
                }}
                layout="horizontal"
                onFinish={handleSubmit}
                initialValues={formData}
              >
                <h4 style={{ textAlign: 'center' }}> Debit Note </h4>
                <Divider />
                <Form.Item label="DebitNote Number" name="debitNoteNumber"
                  rules={[
                    { required: true, message: 'Please enter DebitNote Number..!' },
                  ]}>
                  <div style={{ display: "flex" }}>
                    <Input
                      type="text"
                      value={formData.debitNoteNumber}
                      onChange={(e) => handleFormChange('debitNoteNumber', e.target.value)}
                    />
                    <Tooltip placement="top" title={"Regenerate GRN"}>
                      <Button onClick={reGenerateDebitNoteNumber}>
                        <RetweetOutlined />
                      </Button>
                    </Tooltip>
                  </div>
                </Form.Item>

                <Form.Item label="Purchase Order:"
                  name="PO_Number"
                  htmlFor="po-list"
                  style={{ marginLeft: "1rem" }}
                  rules={[
                    {
                      required: !disabled,
                      message: 'Please enter PO Number..!',
                      validator: (_, value) => {
                        if (!disabled && !value) {
                          return Promise.reject('Please enter PO Number..!');
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}>
                  {disabled ? (
                    <Select
                      filterOption={filterOption}
                      id="po-list"
                      showSearch
                      defaultValue="Search PO List"
                      style={{
                        marginLeft: "1rem",
                        marginTop: "1rem"
                      }}
                      value={formData.PO_Number}
                      disabled
                    />
                  ) : (
                    <Select
                      filterOption={filterOption}
                      id="po-list"
                      showSearch
                      placeholder="Search PO List"
                      style={{
                        marginLeft: "1rem",
                        marginTop: "1rem"
                      }}
                      value={formData.PO_Number}
                      onChange={(e) => handleFormChange('PO_Number', e)}
                      options={poList}
                    />
                  )}
                </Form.Item>

                <Form.Item label="GRN Number" name="GRN_Number">
                  <Select
                    filterOption={filterOption}
                    id="po-list"
                    showSearch
                    defaultValue={formData.GRN_Number || "Select GRN Number"}
                    value={formData.GRN_Number}
                    onChange={(e) => handleFormChange('GRN_Number', e)}
                    options={asnList}
                  />
                </Form.Item>

                <Form.Item label="Amount" name="amount"
                  rules={[
                    { required: true, message: 'Please enter Amount..!' },
                  ]}>
                  <Input
                    type="text"
                    value={formData.amount}
                    onChange={(e) => handleFormChange('amount', e.target.value)}
                  />
                </Form.Item>

                <Form.Item label="description" name="description">
                  <Input
                    type="text"
                    value={formData.description}
                    onChange={(e) => handleFormChange('description', e.target.value)}
                  />
                </Form.Item>

                {/* Contact Person Name End */}

                {/* Submit button Start */}
                <div
                  className="flex"
                  style={{ justifyContent: "center", marginTop: "2rem" }}
                >
                  <Button type="primary" htmlType="submit" loading={loading}>Create Debit Note</Button>
                </div>
                {/* Submit Button end */}
              </Form>

            </section>
          </section>
        </div>
      </main>
    </>
  );
}

export default DebitNote;
