import { Input, Form, Button, Spin, message, Modal } from "antd";
import React, {useEffect, useState} from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import forgotPasswordApi from "../../Apis/forgotPass";


const ForgotPassword = () => {
    // localstates...
    const [modal, contextHolderModal] = Modal.useModal();
    const [optionValue, setOptionValue] = useState("Email");
    const [isLoading, setIsLoading] = useState(false);
    const [isOtpSection, setIsOtpSection] = useState(false);
    const [otpID, setOtpID] = useState("");
    const [disableResend, setDisableResend] = useState(true);
    const [countdown, setCountdown] = useState(60);
    const [loadSet, setloadSet] = useState(false);
    const [LWPData, setLWPData] = useState({
        email: "",
        otp: "",
        newpassword: "",
        confirmpassword: "",
    });
    const [LWPError, setLWPError] = useState({
        email: "",
        otp: "",
        newpassword: "",
        confirmpassword: "",
    });
    const navigate = useNavigate();

    const [messageApi, contextHolder] = message.useMessage();

    const showMessage = (type, text) => {
        if (type === 'info') {
            messageApi.info(text);
        } else if (type === 'error') {
            messageApi.error(text);
        }
    };
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // change input
    const changeInput = (e) => {
        if(e.target.name === "email"){
            if(!e.target.value || e.target.value === "") {
                setLWPError( {...LWPError, [e.target.name]: "Email is required" })
            }
            if (!emailRegex.test(e.target.value)) {
                setLWPError( {...LWPError, [e.target.name]: "Please enter a valid email address" });
            } else{
                setLWPError( {...LWPError, [e.target.name]: "" });
            }
        }
        if(e.target.name === "otp") {
            if (!e.target.value || e.target.value === "") {
                setLWPError({...LWPError, [e.target.name]: "OTP is Required"})
            } else {
                setLWPError({...LWPError, [e.target.name]: ""})
            }
        }
        if(e.target.name === "newpassword") {
            if (!e.target.value || e.target.value === "") {
                setLWPError({...LWPError, [e.target.name]: "Password is required"})
            } else if (e.target.value.length < 4) {
                setLWPError({...LWPError, [e.target.name]: "Password should be at least 4 characters long"})
            } else {
                setLWPError({...LWPError, [e.target.name]: ""})
            }
        }
        if(e.target.name === "confirmpassword") {

            if (!e.target.value || e.target.value === "") {
                setLWPError({...LWPError, [e.target.name]: "Confirm Password is required"})
            } else if (e.target.value !== LWPData.newpassword) {
                setLWPError({...LWPError, [e.target.name]: "Confirm Password and Password should match"})
            } else {
                setLWPError({...LWPError, [e.target.name]: ""})
            }
        }
        setLWPData({ ...LWPData, [e.target.name]: e.target.value });
        console.log(LWPData);
    };

    const sendOTP = async () => {
        if (LWPData.email === "" || !LWPData.email) {
            setLWPError({...LWPError, "email": "Email is required"})
            return
        }
        if(LWPError.email) {
            return
        }
        setloadSet(true)
        const {_id, error} = await forgotPasswordApi.generateOtp(LWPData.email, showMessage)
        if (!error) {
            setOtpID(_id)
            setOptionValue("Otp")
        }
        setloadSet(false)
        setDisableResend(true)
        setCountdown(60)
        setTimeout(() => {
            setDisableResend(false);
        }, 60000);
    }
    const verifyOTP = async () => {
        if (LWPData.otp === "" || !LWPData.otp) {
            setLWPError({...LWPError, "otp": "OTP is required"})
            return
        }
        if(LWPError.otp) {
            return
        }
        setloadSet(true)
        const {error} = await forgotPasswordApi.verify(otpID,LWPData.otp, showMessage)
        if (!error) {
            setOptionValue("Password")
        }
        setloadSet(false)
    }
    const changePsw = async () => {
        if(LWPError.confirmpassword || LWPError.newpassword) {
            return
        }
        if (LWPData.confirmpassword !== LWPData.newpassword) {
            setLWPError({...LWPError, "confirmpassword": "Confirm Password and Password should match"})
            return
        }
        setloadSet(true)
        const {error} = await forgotPasswordApi.changePsw(otpID,LWPData.newpassword, LWPData.confirmpassword, showMessage)
        if (!error) {
            setOptionValue("Done")
        }
        setloadSet(false)
    }
    // option change
    const secretOptionChange = (e) => {
        setOptionValue(e.target.value);
    };
    useEffect(() => {
        let interval;

        // Start the countdown interval
        if (disableResend && countdown > 0) {
            interval = setInterval(() => {
                setCountdown((prevCount) => prevCount - 1);
            }, 1000);
        }

        // Clean up the interval when component unmounts or countdown reaches 0
        return () => {
            clearInterval(interval);
        };
    }, [disableResend, countdown]);
    const doneNavigate = async () => {

        await modal.success({
            content: "Your Password has been reset. You will be redirected to your Dashboard!!",
        })
        setTimeout(() => {
            navigate('/');
        }, 500)
    }
    useEffect(() => {
        if (optionValue === "Done"){
            doneNavigate()
        }
    }, [optionValue]);


    return (
        <>
            {loadSet && <div style={{
                height: '100vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: "absolute",
                width:"100%",
                zIndex: 100,
                background: "#00000099"
            }}>
                <Spin indicator={<LoadingOutlined style={{fontSize: 24}} spin/>}/>
            </div>}
            {contextHolder}
            {contextHolderModal}
            <main
                style={{
                    width: "100%",
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    background: "white"
                }}
            >
                <section
                    className="login_component form"
                >
                    {optionValue === "Done" ? <div className="title">
                        Password Reset Done<br/><span>Redirecting...</span>
                    </div> : <div className="title">
                        Forgot Password?<br/><span>OTP based Password reset</span>
                    </div>}

                    <section className="email_wrp">
                        <Form.Item
                            name="email"
                            onChange={changeInput}
                            rules={[
                                {required: true, message: "Please input your email!"},
                            ]}
                        >
                            <Input placeholder={"Email"} rootClassName={"input"} name="email" disabled={optionValue !== "Email"}/>
                        </Form.Item>
                        <p style={{color: "red"}}>{LWPError.email}</p>
                    </section>
                    {/* email end here */}

                    {/* password and contact goes here */}

                    {/* pasword and contact end here */}

                    {optionValue == "Password" ? (
                            <>
                                <section className="password_wrp">
                                    <Form.Item
                                        name="newpassword"
                                        rules={[
                                            {required: true, message: "Please input your password!"},
                                        ]}
                                        onChange={changeInput}
                                    >
                                        <Input.Password placeholder={"New Password"} rootClassName={"input"}
                                                        name="newpassword"/>
                                    </Form.Item>
                                    <p style={{color: "red"}}>{LWPError.newpassword}</p>
                                </section>
                                <section className="password_wrp">
                                    <Form.Item
                                        name="confirmpassword"
                                        rules={[
                                            {required: true, message: "Please input your password!"},
                                        ]}
                                        onChange={changeInput}
                                    >
                                        <Input.Password placeholder={"Confirm Password"} rootClassName={"input"}
                                                        name="confirmpassword"/>
                                    </Form.Item>
                                    <p style={{color: "red"}}>{LWPError.confirmpassword}</p>
                                </section>
                                <button
                                    style={{
                                        fontWeight: "600",
                                        height: "60px"
                                    }}
                                    className={"button-confirm"}
                                    id={"passwordWiseSignIn"}
                                    onClick={changePsw}
                                >
                                    Reset Password
                                    {isLoading ? (
                                        <Spin
                                            size="small"
                                            indicator={
                                                <LoadingOutlined
                                                    style={{fontSize: 24, color: "white"}}
                                                    spin
                                                />
                                            }
                                        />
                                    ) : (
                                        ""
                                    )}
                                </button>
                            </>


                        ) :
                        ("")
                    }

                    {optionValue === "Email" ? (<button
                        style={{
                        fontWeight: "600",
                        }}
                        className={"button-confirm"}
                        onClick={sendOTP}
                    >
                        Send OTP
                    </button>) : ("") }

                    {optionValue === "Otp" ? (
                        <>
                            <section className="">
                                <Form.Item
                                    name="otp"
                                    rules={[
                                        {required: true, message: "Please input your password!"},
                                    ]}
                                    onChange={changeInput}
                                >
                                    <Input.Password placeholder={"OTP"} rootClassName={"input"}
                                                    name="otp"/>
                                </Form.Item>
                                <p style={{color: "red"}}>{LWPError.otp}</p>
                            </section>
                            <div style={{textAlign: "center"}}>
                                {!disableResend ? <button
                                    style={{
                                        fontWeight: "600",
                                    }}
                                    className={"button-confirm"}
                                    onClick={sendOTP}
                                    disabled={disableResend}
                                >
                                    ReSend OTP
                                </button> : `Resend OTP (${countdown}s)`}
                                <button
                                    style={{
                                        fontWeight: "600",
                                    }}
                                    className={"button-confirm"}
                                    onClick={verifyOTP}
                                >
                                    Verify OTP
                                </button>
                            </div>

                        </>
                    ) : ("")}
                </section>

                {/* otp wrapper goes here */}
                {isOtpSection ?
                    <section
                        className="otp_wrapper"
                        style={{
                            boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                            padding: "1rem",
                            width: "30%",
                            borderRadius: "0.3rem",
                            marginTop: "1rem",
                            background: "white",
                        }}
                    >
                    {/* otp goes here */}
                        <section className="email_wrp" style={{ marginTop: "1rem" }}>
                            <Form.Item
                                label="OTP"
                                name="mobileOtp"
                                rules={[{ required: true, message: "Please input your OTP!" }]}
                            >
                                <Input />
                            </Form.Item>
                        </section>
                        {/* otp end here */}

                        <Button
                            type="primary"
                            style={{
                                fontWeight: "600",
                            }}
                        >
                            Verify OTP
                        </Button>
                    </section>:<></>}
                {/* otp wrapper end here */}

            </main>
        </>
    );
};

export default ForgotPassword;
