import { useEffect, useState } from "react";
import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import Sidebar from "../../components/Sidebar/Sidebar";
import axios from "axios";
import {
  Form,
  Input,
  DatePicker,
  Card,
  Button,
  Checkbox,
  Table,
  Select, notification, Upload, Divider
} from "antd";
import { DeleteFilled, UploadOutlined } from "@ant-design/icons";
import { createASNAPI } from "../../Apis/Asn_apis.js";
// import { headers } from "../../Apis/authHeader"
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ls from "localstorage-slim";
import { getEndDSByPo, getRawDSByPo } from "../../Apis/DS_apis";
import { RetweetOutlined } from "@ant-design/icons";
import uploadFile from "../../s3/s3.upload";
import {useDispatch} from "react-redux";
import {openModal} from "../../app/slices/modelSlice";

function Create_ASN({ PO_Number }) {
  // locals states
  const [listData, setListData] = useState([]);
  const [poList, setPoList] = useState([]);
  const [getRawDsList, setRawDsList] = useState([]);
  const [getTableData, setTableData] = useState([]);
  const [getSelectData, setSelectData] = useState([]);
  const [dsList, setDsList] = useState([]);
  const [asnValue, setASNValue] = useState();
  const [documentData, setDocumentData] = useState([]);
  const [totalDocument, setTotalDocument] = useState([0]);
  const [deliveryScheduleId, setDeliveryScheduleId] = useState(null);
  const [selectedDeliveryScheduleId, setSelectedDeliveryScheduleId] = useState(null);

  const [formData, setFormData] = useState({
    deliveryScheduleId: "",
    PO_Number: "Select PO Number",
    ASN_Number: "",
    dataScheduleId: [],
    eWayBillNo: "",
    vehicleNumber: "",
    expectedDateOfArrival: "",
    shippingDetails: {
      carrier: "",
      shippingAddress: "",
      trackingNumber: "",
    },
    fulfillment: [],
    documentAttachments: [],
  });
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type, message, description) => {
    api[type]({
      message,
      description,
    });
  };
  console.log("FORMDATA:", formData);

  // generate asn
  const generateASN = (e) => {
    const random = `ASN${Date.now()}`;
    setASNValue(random);
    setFormData((prevData) => ({
      ...prevData,
      ASN_Number: random,
    }));
    form.setFieldsValue({ ASN_Number: random });
  };

  //   addData
  const handleInputChange = (index, field, value) => {
    // Update the value of the specified field in the object at the given index
    setDocumentData((prevData) => {
      const newData = [...prevData];
      newData[index][field] = value;
      return newData;
    });
  };

  const handleAddClick = () => {
    // Add an empty object to the array
    setDocumentData((prevData) => [...prevData, { documentType: '', documentLink: '' }]);
  };


  useEffect(() => {
    console.log("huahua", getSelectData);
  }, [getSelectData]);

  useEffect(() => {
    // Assuming you have the initial value available from somewhere
    const initialDeliveryScheduleId = null;
    setSelectedDeliveryScheduleId(initialDeliveryScheduleId);
    setFormData((prevFormData) => ({
      ...prevFormData,
      deliveryScheduleId: initialDeliveryScheduleId,
    }));

    // Set the initial value of the field in the form
    form.setFieldsValue({ deliveryScheduleId: initialDeliveryScheduleId });
  }, []); // This useEffect will run only once, on component mount

  useEffect(() => {
    axios
      .get(getEndDSByPo + encodeURIComponent(formData.PO_Number), {
        headers: {
          Authorization: "Bearer " + ls.get("token", { decrypt: true }),
        },
      })
      .then((res) => {
        console.log("res22endtarun", res);
        setRawDsList(res.data);

        const data = res.data?.map((elm) => ({
          label: elm?._id,
          value: elm?._id,
        }));

        console.log("shaktiman", data);
        setDsList(data);
      })
      .catch((err) => {
        console.error("pubgmobile", err);
      });
  }, [formData.PO_Number]);

  useEffect(() => {
    // Assuming getRawDsList is a state variable holding your data
    const formattedData = [];

    getRawDsList.forEach((rawDs) => {
      if (rawDs.items.some((item) => item.fulfillment.lotwise.length > 0)) {
        // If lotwise data exists, create a table for it
        const lotwiseData = [];
        rawDs.items.forEach((rawmaterial) => {
          rawmaterial.fulfillment.lotwise.forEach((lot) => {
            lotwiseData.push({
              key: lot._id,
              lot_code: lot.lot_code,
              itemName: rawmaterial.item,
              packing_size: lot.packing_size,
              lot_number_from: lot.lot_number_from,
              lot_number_end: lot.lot_number_end,
              price: lot.price,
              quantity: lot.quantity,
              totalAmount: lot.totalAmount,
              delivery_date: lot.delivery_date,
              asnStatus: lot.status,
              status: rawDs.status[0].status,
              deliveryScheduleId: rawDs._id // Adding the raw data id here // Assuming you want the status from rawDs
            });
          });
        });

        formattedData.push({
          type: "lotwise",
          data: lotwiseData,
          title: `Lotwise Table - ${rawDs.PO_Number}`,
        });
      }

      if (rawDs.items.some((item) => item.fulfillment.itemwise.length > 0)) {
        // If itemwise data exists, create a table for it
        const itemwiseData = [];
        rawDs.items.forEach((rawmaterial) => {
          rawmaterial.fulfillment.itemwise.forEach((item) => {
            itemwiseData.push({
              key: item._id,
              itemNumber: item.itemNumber,
              itemName: item.itemName,
              price: item.price,
              quantity: item.quantity,
              totalAmount: item.totalAmount,
              delivery_date: item.delivery_date,
              asnStatus: item.status,
              status: rawDs.status[0].status, // Assuming you want the status from rawDs
              deliveryScheduleId: rawDs._id // Adding the raw data id here
            });
          });
        });

        formattedData.push({
          type: "itemwise",
          data: itemwiseData,
          title: `Itemwise Table - ${rawDs.PO_Number}`,
        });
      }
    });

    setTableData(formattedData);
  }, [getRawDsList]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const navigate = useNavigate();

  const onSelectChange = (selectedKeys) => {
    setSelectedRowKeys(selectedKeys);
  };

  const createASN = () => {
    // Handle ASN creation logic here
    console.log("Create ASN for selected rows:", selectedRowKeys);
  };
  // const handleCheckboxChange = (key, deliveryScheduleId) => (e) => {
  //   const isChecked = e.target.checked;
  //   console.log("Key:", key);
  //   console.log("Delivery Schedule ID:", deliveryScheduleId); // Log the delivery schedule ID
  //   setFormData((prevFormData) => {
  //     if (isChecked) {
  //       // If the checkbox is checked, update the deliveryScheduleId
  //       return {
  //         ...prevFormData,
  //         dataScheduleId: [...prevFormData.dataScheduleId, deliveryScheduleId], // Use deliveryScheduleId
  //       };
  //     } else {
  //       // If the checkbox is unchecked, remove the key from the dataScheduleId array
  //       return {
  //         ...prevFormData,
  //         dataScheduleId: prevFormData.dataScheduleId.filter(
  //           (item) => item !== deliveryScheduleId // Use deliveryScheduleId
  //         ),
  //       };
  //     }
  //   });
  // };
  const handleCheckboxChange = (key, deliveryScheduleId) => (e) => {
    const isChecked = e.target.checked;

    console.log("isChecked:", isChecked, "Key:", key);
    console.log("Delivery Schedule ID:", deliveryScheduleId);

    if (isChecked) {
      setSelectedDeliveryScheduleId(deliveryScheduleId);
      setFormData((prevFormData) => ({
        ...prevFormData,
        deliveryScheduleId: deliveryScheduleId,
        dataScheduleId: [...prevFormData.dataScheduleId, key],
      }));
      form.setFieldsValue({ deliveryScheduleId: deliveryScheduleId });
    } else {

      formData.dataScheduleId.length === 1 && setSelectedDeliveryScheduleId(null);
      setFormData((prevFormData) => ({
        ...prevFormData,
        deliveryScheduleId: "",
        dataScheduleId: prevFormData.dataScheduleId.filter(
          (item) => item !== key
        ),
      }));
      form.setFieldsValue({ deliveryScheduleId: "" });
    }
  };



  const filterTableData = (data) => {
    if (!selectedDeliveryScheduleId) {
      return data; // If no delivery schedule is selected, return all data
    }

    return data.filter(item => item.deliveryScheduleId === selectedDeliveryScheduleId);
  };



  const createCreditNote = (e) => {
    navigate("/create/creditnote");
  };
  const location = useLocation();

  // useEffect(() => {
  //     const fetchData = async () => {
  //         try {
  //             const response = await axios.get(
  //                 "https://vo1urbmb5k.execute-api.ap-south-1.amazonaws.com/dev/api/purchaseOrder/getAllPurchaseOrders", {
  //                 headers: {
  //                     Authorization: "Bearer " + ls.get("token", { decrypt: true }),
  //                 },
  //             });
  //             console.log("GET:ALL-P-Order", response);
  //             setListData(response.data.data);

  //             if (response.data.data.length !== 0) {
  //                 const dropdownData = response.data.data.map((po) => ({
  //                     value: po.PO_Number,
  //                     label: `${po.buyerID}---${po.PO_Number}---${po.currency}---${po.deliveryAddress}---${po.documentDate}---${po.totalAmount}---${po.vendorID}`,
  //                 }));
  //                 setPoList(dropdownData);
  //             }
  //         } catch (error) {
  //             console.error("GET:ALL-P-Order", error);
  //         }
  //     };

  //     console.log(location, "location");
  //     if (location.state && location.state.viewId) {
  //         // Update the PO_Number in the form data
  //         setFormData((prevState) => ({
  //             ...prevState,
  //             PO_Number: location.state.viewId,
  //             eWayBillNo: "tfghfkhgfkugfh"
  //         }));
  //     } else {
  //         fetchData();
  //     }
  // }, [location.state]);

  useEffect(() => {
    // axios.get(getEndDSByPo);

    // console.log(location?.state.viewId, "location");
    if (location.state && location.state.viewId) {
      // Update the PO_Number in the form data
      setFormData((prevState) => ({
        ...prevState,
        PO_Number: location.state.viewId,
      }));
      form.setFieldsValue({ PO_Number: location.state.viewId });
      const element = document.getElementById("po-list");
      console.log(element);
      if (element) {
        element.disabled = true;
      }
    }
    axios
      .get(
        "https://vo1urbmb5k.execute-api.ap-south-1.amazonaws.com/dev/api/purchaseOrder/getAllPurchaseOrders",
        {
          headers: {
            Authorization: "Bearer " + ls.get("token", { decrypt: true }),
          },
        }
      )
      .then((res) => {
        console.log("GET:ALL-P-Order", res);
        setListData(res.data.data);
        if (res.data.data.length !== 0) {
          const dropdownData = res.data.data.map(
            (po) => (
              console.log("POO", po),
              {
                value: po.PO_Number,
                label: `${po.buyerID}---${po.PO_Number}---${po.currency}---${po.deliveryAddress}---${po.documentDate}---${po.totalAmount}---${po.vendorID}`,
              }
            )
          );
          setPoList(dropdownData);
        }
      })
      .catch((err) => {
        console.log("GET:ALL-P-Order", err);
      });
    generateASN()
  }, []);

  const UploadFiles = async () => {
    try {
      const currentDate = new Date();
      const formattedDate = currentDate.toLocaleDateString('en-US', {
        day: '2-digit',
        month: 'short',
        year: 'numeric'
      });
      console.log("we in");

      // Map over the fileList and return an array of promises
      const uploadPromises = fileList.map(async (file, index) => {
        let newFileName = documentData[index].documentType || "default";
        newFileName = newFileName + "-" + formattedDate;
        newFileName = newFileName.replace(/[^a-zA-Z0-9]/g, "-");

        // Assuming uploadFile is an async function that returns a promise
        const fileUrl = await uploadFile(formData.PO_Number, "default", file, newFileName);
        handleInputChange(index, "documentLink", fileUrl);
        console.log(newFileName, fileUrl, "see me upload");
      });

      // Wait for all uploads to complete
      await Promise.all(uploadPromises);
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    console.log(documentData, "docdata")
  }, [documentData]);

  const handleSubmit = async () => {
    UploadFiles()
    // return
    try {
      await setLoading(true);
      console.log(getSelectData)
      // Constructing the updated fulfillment array outside the loop
      const updatedFulfillment = [];

      // Update only the necessary fields in the formData
      setFormData((prevData) => ({
        ...prevData,
        fulfillment: updatedFulfillment,
        documentAttachments: documentData
      }));

      console.log(formData, "formdata submitted");

      // Handle success or additional actions after form submission
      console.log("Form submitted successfully!");

      // Await the axios.post call to get the response
      const response = await axios.post(createASNAPI, formData, {
        headers: {
          Authorization: "Bearer " + ls.get("token", { decrypt: true }),
        },
      });

      // Handle response
      console.log("Response", response.data);

      // Show alert based on response status
      if (response.status === 200) {

        let redirecto = location.state.viewId ? "PO List" : "ASN List"
        let espp = "ASN Generated for po: " + JSON.stringify(formData.PO_Number) + ". You will be redirected to " + redirecto
        dispatch(openModal({
          type: 'success',
          message: espp,
          redirectRoute: location.state.viewId ? '/p-order/lists' : '/asn/lists',
        }))
        // openNotificationWithIcon(
        //   "success",
        //   "SUBMITTED Successfully",
        //   "ASN Generated for po: " + JSON.stringify(formData.PO_Number)
        // );
        // response.data.PO_Number && navigate("/p-order/lists")
        // form.resetFields();
        // setFormData({
        //   deliveryScheduleId: "",
        //   PO_Number: "Select PO Number",
        //   ASN_Number: "",
        //   dataScheduleId: [],
        //   eWayBillNo: "",
        //   vehicleNumber: "",
        //   expectedDateOfArrival: "",
        //   shippingDetails: {
        //     carrier: "",
        //     shippingAddress: "",
        //     trackingNumber: "",
        //   },
        //   fulfillment: [],
        //   documentAttachments: [],
        // })

      } else {
        console.error("Error:", response);
        openNotificationWithIcon(
            "error",
            "an error occurred",
            "error: " + JSON.stringify(response.data)
        );
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      openNotificationWithIcon(
        "error",
        "an error accord",
        "error: " + JSON.stringify(error.response.data)
      );
    } finally {
      setLoading(false);
    }
  };

  // Table data
  // const data = tableData
  //     ? tableData.flatMap((table) =>
  //         table.data.map((item) => ({

  //             item: item.item,
  //             scheduledDeliveryDate: item.delivery_date,
  //             quantity: item.quantity,
  //             // ... other properties you need
  //         }))
  //     )
  //     : [];
  // console.log(data, "dataaaa")

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  console.log(formData, "890");

  const lotwiseColumns = [
    {
      title: "Item Name",
      dataIndex: "itemName",
      key: "itemName",
    },
    { title: "Lot Code", dataIndex: "lot_code", key: "lot_code" },
    { title: "Packing Size", dataIndex: "packing_size", key: "packing_size" },
    {
      title: "lot_number_from",
      dataIndex: "lot_number_from",
      key: "lot_number_from",
    },

    {
      title: "Lot number_end",
      dataIndex: "lot_number_end",
      key: "lot_number_end",
    },
    { title: "price", dataIndex: "price", key: "price" },
    { title: "quantity", dataIndex: "quantity", key: "quantity" },
    { title: "totalAmount", dataIndex: "totalAmount", key: "totalAmount" },
    {
      title: "ScheduleDeliveryDate",
      dataIndex: "delivery_date",
      key: "delivery_date",
    },
    { title: "Status", dataIndex: "status", key: "status" },
    {
      title: "Select",
      dataIndex: "key",
      key: "key",
      render: (key, record) => (
        <Checkbox
          checked={formData.dataScheduleId.includes(key)}
          // value={record.deliveryScheduleId === selectedDeliveryScheduleId}
          onChange={handleCheckboxChange(key, record.deliveryScheduleId)}
        >
          {console.log("record.deliveryScheduleId ", record)}
          Select
        </Checkbox>

      ),
    },
  ];
  const itemwiseColumns = [
    { title: "Item Number", dataIndex: "itemNumber", key: "itemNumber" },
    { title: "Item Name", dataIndex: "itemName", key: "itemName" },
    { title: "Price", dataIndex: "price", key: "price" },
    { title: "Quantity", dataIndex: "quantity", key: "quantity" },
    { title: "Total Amount", dataIndex: "totalAmount", key: "totalAmount" },
    {
      title: "Delivery Date",
      dataIndex: "delivery_date",
      key: "delivery_date",
    },
    { title: "Status", dataIndex: "status", key: "status" },
    {
      title: "Select",
      dataIndex: "key",
      key: "key",
      render: (key, record) => (
        <Checkbox
          checked={formData.dataScheduleId.includes(key)}
          // value={record.deliveryScheduleId === selectedDeliveryScheduleId}
          onChange={handleCheckboxChange(key, record.deliveryScheduleId)}
        >
          Select
        </Checkbox>

      ),
    },
  ];

  const props = {
    onRemove: (fileName) => {
      const index = fileList.findIndex(file => file.name === fileName);
      if (index !== -1) {
        const newFileList = [...fileList];
        newFileList.splice(index, 1);
        setFileList(newFileList);

        setDocumentData((prevData) => {
          const newData = [...prevData];
          const dataToRemoveIndex = newData.findIndex(item => item.documentLink === fileName);
          if (dataToRemoveIndex !== -1) {
            newData.splice(dataToRemoveIndex, 1);
          }
          return newData;
        });
      }

    },
    beforeUpload: (file) => {
      console.log(file.type)
      const isPDF = file.type === 'application/pdf';
      if (!isPDF) {
        alert("Only PDF are supported at the moment!")
        return true
      }
      setDocumentData((prevData) => [
        ...prevData,
        { documentType: '', documentLink: file.name },
      ]);

      setFileList([...fileList, file]);
      return false;
    },
    fileList,
  };

  const handleVehicleInput = (e) => {
    const inputValue = e.target.value;
    const upperCaseValue = inputValue.toUpperCase();
    const filteredValue = upperCaseValue.replace(/[^A-Z0-9]/g, '');

    setFormData((prevData) => ({
      ...prevData,
      vehicleNumber: filteredValue,
    }));
    form.setFieldValue("vehicleNumber", filteredValue)
  };

  return (
    <>
      <main style={{ backgroundColor: "#80" }}>
        {contextHolder}
        <div className="flex" style={{ backgroundColor: "#80" }}>
          <Sidebar />

          {/* Wrapper goes here */}
          <section
            className="dashboard_wrapper"
            style={{ width: "80%", backgroundColor: "#80" }}
            loading={loading}
          >
            <Navbar title={'ASN / Create ASN'} />

            {/* ASN lists table goes here */}
            <Form form={form} name="myForm" setFieldsValue={formData}
              onFinish={handleSubmit}>
              <section
                className="lists_table"
                style={{
                  background: "white",
                  boxShadow:
                    "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
                  height: "80vh",
                  width: '100%',
                  margin: "1rem auto",
                  padding: "1rem",
                }}
              >
                <h4 style={{ textAlign: 'center' }}>Advanced Shipping Notice</h4>
                <Divider />
                {/* ASN lists table end here */}

                {/* PO Number Start */}
                <Form.Item
                  label="Purchase order:"
                  name="PO_Number"
                  rules={[
                    { required: true, message: 'Please select PO Number..!' },
                  ]}
                >
                  <Select
                    filterOption={filterOption}
                    id="po-list"
                    showSearch
                    placeholder="Please select PO Number"
                    defaultValue={formData.PO_Number}
                    value={formData.PO_Number}
                    onChange={(e) => {
                      setFormData((prevState) => ({
                        ...prevState,
                        PO_Number: e,
                      }));
                      setSelectedDeliveryScheduleId(null);
                      form.setFieldsValue({ deliveryScheduleId: null });
                    }}
                    options={poList}
                  />

                </Form.Item>

                {/* ASN Number Start */}
                <Form.Item
                  label="Delivery Schedule ID"
                  name="deliveryScheduleId"
                  rules={[{ required: true, message: 'Please select Delivery Schedule ID..!' }]}
                >
                  <Input
                    id="ds-list"
                    showSearch
                    onChange={(value) => setSelectedDeliveryScheduleId(value)}
                    value={selectedDeliveryScheduleId}
                    options={dsList}
                    disabled
                  >
                    {console.log("selectedDeliveryScheduleId:", selectedDeliveryScheduleId)}
                  </Input>
                </Form.Item>



                {/* ASN Number Start */}
                <Form.Item label="ASN Number" name="ASN_Number"
                  rules={[
                    { required: true, message: 'Please generate ASN Number..!' },
                  ]}
                  shouldUpdate>
                  <Input
                    type="text"
                    value={formData.ASN_Number}
                    addonAfter={<Button onClick={generateASN}>
                      <RetweetOutlined />
                    </Button>}
                  />

                </Form.Item>

                {/* EWay Bill No Start */}
                <Form.Item label="EWay Bill No" name="eWayBillNo">
                  <Input
                    type="text"
                    value={formData.eWayBillNo}
                    onChange={(e) =>
                      setFormData((prevData) => ({
                        ...prevData,
                        eWayBillNo: e.target.value,
                      }))
                    }
                  />
                </Form.Item>

                {/* Vehicle Number Start */}
                <Form.Item label="Vehicle Number" name="vehicleNumber">
                  <Input
                    type="text"
                    value={formData.vehicleNumber}
                    onChange={handleVehicleInput}
                  />
                </Form.Item>

                {/* Expected Date of Arrival Start */}
                <Form.Item
                  label="Expected Date of Arrival"
                  name="expectedDateOfArrival"
                  rules={[
                    { required: true, message: 'Please select Expected Date of Arrival..!' },
                  ]}
                >
                  <DatePicker
                    value={formData.expectedDateOfArrival}
                    onChange={(e) =>
                      setFormData((prevData) => ({
                        ...prevData,
                        expectedDateOfArrival: e.$d,
                      }))
                    }
                    style={{ width: "100%" }}
                  />
                </Form.Item>

                {/* Shipping Details Start */}
                <Form.Item label="Shipping Details" name="shippingDetails">
                  <Card
                    bordered={true}
                    style={{ border: "1px solid lightgrey" }}
                  >
                    <Form.Item label="Carrier" name="carrier">
                      <Input
                        type="text"
                        value={formData.shippingDetails.carrier}
                        onChange={(e) =>
                          setFormData((prevData) => ({
                            ...prevData,
                            shippingDetails: {
                              ...prevData.shippingDetails,
                              carrier: e.target.value,
                            },
                          }))
                        }
                      />
                    </Form.Item>
                    <Form.Item label="Tracking Number" name="trackingNumber">
                      <Input
                        type="text"
                        value={formData.shippingDetails.trackingNumber}
                        onChange={(e) =>
                          setFormData((prevData) => ({
                            ...prevData,
                            shippingDetails: {
                              ...prevData.shippingDetails,
                              trackingNumber: e.target.value,
                            },
                          }))
                        }
                      />
                    </Form.Item>
                    <Form.Item label="Shipping Address" name="shippingAddress">
                      <Input
                        type="text"
                        value={formData.shippingDetails.shippingAddress}
                        onChange={(e) =>
                          setFormData((prevData) => ({
                            ...prevData,
                            shippingDetails: {
                              ...prevData.shippingDetails,
                              shippingAddress: e.target.value,
                            },
                          }))
                        }
                      />
                    </Form.Item>
                  </Card>
                </Form.Item>

                {/* Fullfillment Start */}
                <Form>
                  <Divider orientation="left" plain>
                    <h5>Fullfillment</h5>
                  </Divider>
                  {selectedDeliveryScheduleId !== null &&
                    getTableData.map((table, index) => {
                      if (table.data.length > 0 && table.data.some(item => item.asnStatus === "ASN pending" && item.deliveryScheduleId === selectedDeliveryScheduleId)) {
                        return (
                          <div key={index}>
                            {console.log("table:", table, "getTableData:", getTableData, "index:", index)}
                            <h5>{table.title}</h5>
                            <Table
                              style={{ marginTop: "1rem" }}
                              dataSource={filterTableData(table.data.filter(item => item.asnStatus === "ASN pending"))}
                              columns={table.type === "lotwise" ? lotwiseColumns : itemwiseColumns}
                            />
                          </div>
                        );
                      } else {
                        return null;
                      }
                    })
                  }

                  {selectedDeliveryScheduleId === null &&
                    getTableData.map((table, index) => {
                      if (table.data.length > 0 && table.data.some(item => item.asnStatus === "ASN pending")) {
                        return (
                          <div key={index}>
                            <h5>{table.title}</h5>
                            <Table
                              style={{ marginTop: "1rem" }}
                              dataSource={table.data.filter(item => item.asnStatus === "ASN pending")}
                              columns={table.type === "lotwise" ? lotwiseColumns : itemwiseColumns}
                            />
                          </div>
                        );
                      } else {
                        return null;
                      }
                    })
                  }



                </Form>

                {/* Document Attachments Start  */}
                <Divider orientation="left" plain>
                  <h5>Upload Certificates</h5>
                </Divider>

                {documentData.map((elm, index) => (
                  <div
                    key={index}
                    className="flex"
                    style={{ justifyContent: "space-between" }}
                  >
                    <Input
                      placeholder="Document Type"
                      name="documentType"
                      value={elm.documentType}
                      onChange={(e) => handleInputChange(index, 'documentType', e.target.value)}
                      style={{ width: "49%" }}
                    />
                    <Input
                      placeholder="Document Link"
                      name="documentLink"
                      value={elm.documentLink}
                      style={{ width: "49%", color: "darkgray" }}
                      disabled={true}
                    />
                    <Button
                      type="primary"
                      icon={<DeleteFilled />}
                      loading={false}
                      onClick={() => props.onRemove(elm.documentLink)}
                    />
                  </div>
                ))}
                <Upload {...props}
                  showUploadList={false}
                >
                  <Button icon={<UploadOutlined />}>Select File</Button>
                </Upload>

                {/* Submit Button Start */}
                <div
                  className="flex"
                  style={{ justifyContent: "center", marginTop: "2rem" }}
                >
                  <Button
                    htmlType="submit"
                    type="primary" style={{ marginRight: "1rem" }}
                    loading={loading}>
                    Create ASN
                  </Button>
                </div>
              </section>
            </Form>
          </section>

          {/* Wrapper ends here */}
        </div>
      </main>
    </>
  );

  // Table Columns
}

export default Create_ASN;
