import React, { useState, useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Sidebar from "../../components/Sidebar/Sidebar";
import { Form, Input, Button, Radio, Tooltip, Checkbox, Table, Select, notification, Divider } from "antd";
import { useLocation } from "react-router-dom";
import { RetweetOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import ls from "localstorage-slim";
import axios from "axios";
import { getAllASN } from "../../Apis/Asn_apis";
import { createGRNAPI } from "../../Apis/GRN_apis";
import { getEndDSByPo } from "../../Apis/DS_apis";
import {openModal} from "../../app/slices/modelSlice";
import {useDispatch} from "react-redux";

function Create_GRN() {
  // localstates...;
  const [grnNumber, setGrnNumber] = useState("");
  const [buyerID, setBuyerId] = useState("");
  const [vendorID, setVenderId] = useState("");
  const [ASN_Number, setAsnNumber] = useState("");
  const [PO_Number, setPoNumber] = useState("");
  const [poList, setPoList] = useState([]);
  const [asnList, setAsnList] = useState([]);
  const [getRawAsnList, setRawAsnList] = useState([]);
  const [listData, setListData] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [getRawDsList, setRawDsList] = useState([]);
  const [getTableData, setTableData] = useState([]);
  const [dsList, setDsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    vendorID: "",
    ASN_Number: "",
    GRN_Number: `GRN${Date.now()}`,
    PO_Number: "",
    fulfillment: [],
    dataScheduleId: [],
  });
  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type, message, description) => {
    api[type]({
      message,
      description,
    });
  };
  const dispatch = useDispatch()
  useEffect(() => {
    axios
      .get(getEndDSByPo + encodeURIComponent(formData.PO_Number), {
        headers: {
          Authorization: "Bearer " + ls.get("token", { decrypt: true }),
        },
      })
      .then((res) => {
        console.log("res22endtarun", res);
        setRawDsList(res.data);

        const data = res.data?.map((elm) => ({
          label: elm?._id,
          value: elm?._id,
        }));

        console.log("shaktiman", data);
        setDsList(data);
      })
      .catch((err) => {
        console.error("pubgmobile", err);
      });
  }, [formData.PO_Number]);

  useEffect(() => {
    // Assuming getRawDsList is a state variable holding your data

  }, [getRawDsList]);


  const navigate = useNavigate();

  // get po id here
  const location = useLocation();
  const [form] = Form.useForm();
  useEffect(() => {
    axios
      .get(getEndDSByPo + encodeURIComponent(formData.PO_Number), {
        headers: {
          Authorization: "Bearer " + ls.get("token", { decrypt: true }),
        },
      })
      .then((res) => {
        console.log("res22endtarun", res);
        setRawDsList(res.data);

        const data = res.data?.map((elm) => ({
          label: elm?._id,
          value: elm?._id,
        }));

        console.log("shaktiman", data);
        setDsList(data);
      })
      .catch((err) => {
        console.error("pubgmobile", err);
      });
    form.resetFields();
  }, [formData.PO_Number, form]);

  useEffect(() => {
    setGrnNumber(`GRN${Date.now()}`);
    if (location.state && location.state.viewId) {
      // Update the PO_Number in the form data
      console.log("location.state.viewId", location.state.viewId)
      setFormData(prevState => ({
        ...prevState,
        PO_Number: location.state.viewId,
      }));
      setDisabled(true)
      const element = document.getElementById('po-list');
      console.log(element)
      if (element) {
        element.disabled = true;
      }
    }
    axios
      .get(
        "https://vo1urbmb5k.execute-api.ap-south-1.amazonaws.com/dev/api/purchaseOrder/getAllPurchaseOrders",
        {
          headers: {
            Authorization:
              "Bearer " + ls.get("token", { decrypt: true }),
          },
        }
      )
      .then((res) => {
        console.log("GET:ALL-P-Order", res);
        setListData(res.data.data);
        if (res.data.data.length !== 0) {
          const dropdownData = res.data.data.map((po) => ({
            value: po.PO_Number,
            label: `${po.buyerID}---${po.PO_Number}---${po.currency}---${po.deliveryAddress}---${po.documentDate}---${po.totalAmount}---${po.vendorID}`,
          }));
          setPoList(dropdownData);
        }
      })
      .catch((err) => {
        console.log("GET:ALL-P-Order", err);
      });
  }, [])

  useEffect(() => {
    if (formData.PO_Number === "") {
      return
    }
    else {
      axios.get(
        getAllASN + encodeURIComponent(formData.PO_Number),
        {
          headers: {
            Authorization:
              "Bearer " + ls.get("token", { decrypt: true }),
          },
        }).then(res => {
          console.log("resAsn", res);
          setRawAsnList(res.data)
          if (res.data.length !== 0) {
            const dropdownData = res.data.map((po) => ({
              value: po.ASN_Number,
              label: `${po.ASN_Number}---${po.PO_Number}---${po.eWayBillNo}---${po.shippingDetails.shippingAddress}`,
            }));
            setAsnList(dropdownData);
          }
        }).catch(err => {
          console.error("errere ", err)
        })
    }
  }, [formData.PO_Number])


  useEffect(() => {
    console.log(formData)
  }, [formData]);
  useEffect(() => {

    const foundASN = getRawAsnList.find(asn => asn.ASN_Number === formData.ASN_Number);

    console.log(getRawAsnList)
    if (foundASN) {
      const dataScheduleId = foundASN.dataScheduleId;
      // setFormData([..., dataScheduleId: foundASN.dataScheduleId])
      console.log(dataScheduleId, "dataid", getRawDsList);

      const formattedData = [];
      getRawDsList.forEach((rawDs) => {
        if (rawDs.items.some((item) => item.fulfillment.lotwise.length > 0)) {
          // If lotwise data exists, create a table for it
          const lotwiseData = [];
          rawDs.items.forEach((rawmaterial) => {
            rawmaterial.fulfillment.lotwise.forEach((lot) => {
              if (dataScheduleId.includes(lot._id.toString())) {
                lotwiseData.push({
                  key: lot._id,
                  lot_code: lot.lot_code,
                  itemName: rawmaterial.item,
                  packing_size: lot.packing_size,
                  lot_number_from: lot.lot_number_from,
                  lot_number_end: lot.lot_number_end,
                  price: lot.price,
                  quantity: lot.quantity,
                  totalAmount: lot.totalAmount,
                  delivery_date: lot.delivery_date,
                  status: rawDs.status[0].status, // Assuming you want the status from rawDs
                });
              }
            });
          });
          if (lotwiseData.length <= 0) {

          } else {
            formattedData.push({
              type: "lotwise",
              data: lotwiseData,
              title: `Lotwise Table - ${rawDs.PO_Number}`,
            });
          }

        }

        if (rawDs.items.some((item) => item.fulfillment.itemwise.length > 0)) {
          // If itemwise data exists, create a table for it
          const itemwiseData = [];
          rawDs.items.forEach((rawmaterial) => {
            rawmaterial.fulfillment.itemwise.forEach((item) => {
              if (dataScheduleId.includes(item._id.toString())) {
                itemwiseData.push({
                  key: item._id,
                  itemNumber: item.itemNumber,
                  itemName: item.itemName,
                  price: item.price,
                  quantity: item.quantity,
                  totalAmount: item.totalAmount,
                  delivery_date: item.delivery_date,
                  status: rawDs.status[0].status, // Assuming you want the status from rawDs
                });
              }
            });
          });
          if (itemwiseData.length <= 0) {

          } else {
            formattedData.push({
              type: "itemwise",
              data: itemwiseData,
              title: `Itemwise Table - ${rawDs.PO_Number}`,
            });
          }

        }
      });

      setTableData(formattedData);

    } else {
      // Handle the case where no matching ASN_Number is found
      console.log("ASN not found");
    }
  }, [formData.ASN_Number,])
  // useEffect(() => {
  //   console.log(location, "location");
  //   setGrnNumber(`GRN${Date.now()}`);
  //   if (location.state && location.state.viewId) {
  //     // Update the PO_Number in the form data
  //     setFormData(prevState => ({
  //       ...prevState,
  //       PO_Number: location.state.viewId,
  //     }));
  //     const element = document.getElementById('po-list');
  //     console.log(element)
  //     if (element) {
  //       element.disabled = true;
  //     }
  //   }
  //   axios
  //     .get(
  //       "https://vo1urbmb5k.execute-api.ap-south-1.amazonaws.com/dev/api/purchaseOrder/getAllPurchaseOrders",
  //       {
  //         headers: {
  //           Authorization:
  //             "Bearer " + ls.get("token", { decrypt: true }),
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       console.log("GET:ALL-P-Order", res);
  //       setListData(res.data.data);
  //       if (res.data.data.length !== 0) {
  //         const dropdownData = res.data.data.map((po) => ({
  //           value: po.PO_Number,
  //           label: `${po.buyerID}---${po.PO_Number}---${po.currency}---${po.deliveryAddress}---${po.documentDate}---${po.totalAmount}---${po.vendorID}`,
  //         }));
  //         setPoList(dropdownData);
  //         const vendorIDs = res.data.data.map((po) => po.vendorID);
  //         console.log("Vendor IDs:", vendorIDs);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("GET:ALL-P-Order", err);
  //     });
  // }, []);


  //   regenerate grm function
  const reGenerateGrn = (e) => {
    setGrnNumber(`GRN${Date.now()}`);
  };



  const createDebitNote = async (e) => {
    try {
      // setLoading(true); // Set loading to true before navigating
      navigate("/create/debitnote");
    } catch (error) {
      console.error(error);
    }
  };

  // Create GRN
  const handleSubmit = async () => {
    try {
      setLoading(true);
      // console.log(getSelectData)
      // Constructing the updated fulfillment array outside the loop
      const updatedFulfillment = [];

      // Update only the necessary fields in the formData
      setFormData((prevData) => ({
        ...prevData,
        fulfillment: updatedFulfillment,
      }));

      console.log(formData, "formdata submitted");

      // Handle success or additional actions after form submission
      console.log("Form submitted successfully!");
      // Construct fulfillment object based on the selected listType

      // Handle the form data as needed
      console.log('Form submitted locally:', formData);

      await axios.post(createGRNAPI, formData, {
        headers: {
          Authorization:
            "Bearer " + ls.get("token", { decrypt: true }),
        },
      }).then(res => {
        console.log("ccn", res)
        const isPOList = location.state && location.state.viewId;
        const redirectRoute = isPOList ? '/p-order/lists' : '/grn/lists';
        const viewName = isPOList ? "PO List" : "GRN List";
        let message = `GRN Generated for po: ${formData.PO_Number}. You will be redirected to ${viewName}.`;


        dispatch(openModal({
          type: 'success',
          message: message,
          redirectRoute: redirectRoute,
        }))
        // openNotificationWithIcon(
        //   "success",
        //   "SUBMITTED Successfully",
        //   "GRN saved in database for PO: " + JSON.stringify(res.data.PO_Number)
        // );
        // form.resetFields();
      }).catch(err => {
        console.error("errccn", err)
        openNotificationWithIcon(
          "error",
          "GRN failed",
          "error: " + JSON.stringify(err.response.data)
        );
      })

      // Optionally, you can reset the form fields after successful submission
      setFormData({
        vendorID: "",
        ASN_Number: "",
        GRN_Number: `GRN${Date.now()}`,
        PO_Number: "",
        fulfillment: [],
        dataScheduleId: [],
      });


      // You can add additional logic here, such as navigation or displaying a success message.
    } catch (error) {
      console.error('Error submitting form:', error);
      // Handle error state if the submission fails
    } finally {
      setLoading(false); // Set loading to false after form submission completes
    }
  };

  const handleCheckboxChange = (key) => (e) => {
    const isChecked = e.target.checked;

    setFormData((prevFormData) => {
      if (isChecked) {
        // If the checkbox is checked, add the key to the dataScheduleId array
        return {
          ...prevFormData,
          dataScheduleId: [...prevFormData.dataScheduleId, key],
        };
      } else {
        // If the checkbox is unchecked, remove the key from the dataScheduleId array
        return {
          ...prevFormData,
          dataScheduleId: prevFormData.dataScheduleId.filter(
            (item) => item !== key
          ),
        };
      }
    });
  };


  const lotwiseColumns = [
    {
      title: "Item Name",
      dataIndex: "itemName",
      key: "itemName",
    },
    { title: "Lot Code", dataIndex: "lot_code", key: "lot_code" },
    { title: "Packing Size", dataIndex: "packing_size", key: "packing_size" },
    {
      title: "lot_number_from",
      dataIndex: "lot_number_from",
      key: "lot_number_from",
    },

    {
      title: "Lot number_end",
      dataIndex: "lot_number_end",
      key: "lot_number_end",
    },
    { title: "price", dataIndex: "price", key: "price" },
    { title: "quantity", dataIndex: "quantity", key: "quantity" },
    { title: "totalAmount", dataIndex: "totalAmount", key: "totalAmount" },
    {
      title: "ScheduleDeliveryDate",
      dataIndex: "delivery_date",
      key: "delivery_date",
    },
    { title: "Status", dataIndex: "status", key: "status" },
    {
      title: "Select",
      dataIndex: "key",
      key: "key",
      render: (key) => (
        <Checkbox value={key} onChange={handleCheckboxChange(key)}>
          Select
        </Checkbox>
      ),
    },
  ];
  const itemwiseColumns = [
    { title: "Item Number", dataIndex: "itemNumber", key: "itemNumber" },
    { title: "Item Name", dataIndex: "itemName", key: "itemName" },
    { title: "Price", dataIndex: "price", key: "price" },
    { title: "Quantity", dataIndex: "quantity", key: "quantity" },
    { title: "Total Amount", dataIndex: "totalAmount", key: "totalAmount" },
    {
      title: "Delivery Date",
      dataIndex: "delivery_date",
      key: "delivery_date",
    },
    { title: "Status", dataIndex: "status", key: "status" },
    // {
    //   title: "Select",
    //   dataIndex: "key",
    //   key: "key",
    //   render: (key) => (
    //     <Checkbox value={key} onChange={handleCheckboxChange(key)}>
    //       Select
    //     </Checkbox>
    //   ),
    // },
  ];


  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <>
      <main style={{ backgroundColor: "#80" }}>
        {contextHolder}
        <div className="flex" style={{ backgroundColor: "#80" }}>
          <Sidebar />

          <section
            className="dashboard_wrapper"
            style={{ width: "80%", backgroundColor: "#80" }}
          >
            <Navbar title={'GRN / Create GRN'} />

            <section
              className="lists_table"
              style={{
                background: "white",
                boxShadow:
                  "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
                height: "88vh",
                width: "98%",
                margin: "1rem auto",
                padding: "1rem",
              }}
            >
              <h4 style={{ textAlign: 'center' }}> Goods Received Note </h4>
              <Divider />
              <Form
                form={form}
                labelCol={{
                  span: 4,
                }}
                wrapperCol={{
                  span: 14,
                }}
                layout="horizontal"
                initialValues={{
                  size: "default",
                }}
                onFinish={handleSubmit}
              >
                {/* PO Number start */}
                <Form.Item label="Purchase Order:"
                  htmlFor="po-list"
                  name="PO_Number"
                  style={{ marginLeft: "1rem" }}
                >
                  {disabled ? (
                    <Select
                      filterOption={filterOption}
                      id="po-list"
                      showSearch
                      // defaultValue="Search Buyer Name"
                      defaultValue={formData.PO_Number}
                      style={{
                        marginLeft: "1rem",
                        marginTop: "1rem"
                      }}
                      value={formData.PO_Number}
                      disabled
                    />
                  ) : (
                    <Select
                      filterOption={filterOption}
                      id="po-list"
                      showSearch
                      // placeholder="Search Buyer Name"
                      defaultValue={formData.PO_Number}
                      style={{
                        marginLeft: "1rem",
                        marginTop: "1rem"
                      }}
                      value={formData.PO_Number}
                      onChange={(e) => {
                        console.log(e)
                        setFormData(prevState => ({
                          ...prevState,
                          PO_Number: e,
                        }));
                      }}
                      options={poList}
                    />
                  )}
                </Form.Item>
                {/* PO Number End */}

                {/* ASN Number Start */}
                <Form.Item label="ASN Number" name="ASN_Number"
                  rules={[
                    { required: true, message: 'Please select ASN Number..!' },
                  ]}>
                  <Select
                    filterOption={filterOption}
                    id="po-list"
                    showSearch
                    defaultValue={formData.ASN_Number || "Select ASN Number"}
                    value={formData.ASN_Number}
                    onChange={(e) => {
                      console.log(e)
                      setFormData(prevState => ({
                        ...prevState,
                        ASN_Number: e,
                      }));
                    }}
                    options={asnList}
                  />
                </Form.Item>
                {/* ASN Number End */}
                <Form.Item label="GRN Number" name="GRN_Number"
                  shouldUpdate>
                  <Input type="text" value={grnNumber}
                  />

                  <RetweetOutlined
                    onClick={reGenerateGrn}
                    style={{ border: "1px solid grey", cursor: "pointer" }}
                  />
                </Form.Item>

                {/* <Form.Item label="Buyer ID" name="buyerID">
                  <Input
                    type="text"
                    value={buyerID}
                    onChange={(e) => {
                      setBuyerId(e.target.value);
                    }}
                  />
                </Form.Item> */}

                {/* Fullfillment Start */}
                {/* <Form>
                  <h5>Fullfillment:</h5>
                  <Table columns={columns} dataSource={data} bordered />
                </Form> */}
                <Form>
                  <h5>Fullfillment:</h5>
                  {getTableData.map((table, index) => (
                    <div key={index}>
                      <h5>{table.title}</h5>
                      <Table
                        style={{ marginTop: "1rem" }}
                        dataSource={table.data}
                        columns={
                          table.type === "lotwise"
                            ? lotwiseColumns
                            : itemwiseColumns
                        }
                      />
                    </div>
                  ))}
                </Form>
                {/* Fullfillment End */}

                <div
                  className="flex"
                  style={{ justifyContent: "center", marginTop: "2rem" }}
                >
                  <Button type="primary" htmlType="submit" style={{ marginRight: "1rem" }} loading={loading}>Create GRN</Button>
                  <Button type="primary" onClick={createDebitNote}>
                    Create Debit Note
                  </Button>
                </div>
              </Form>
            </section>
          </section>
        </div>
      </main>
    </>
  );
}

export default Create_GRN;
