// ModalComponent.js

import React, {useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'antd';
import { closeModal } from '../app/slices/modelSlice';
import {useNavigate} from "react-router-dom";

const ModalComponent = () => {
    const modalState = useSelector((state) => state.modal);
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [modal, contextHolderModal] = Modal.useModal();

    const callModal = async () => {
        await modal[modalState.type]({
            content: modalState.message,
        })
        setTimeout(() => {
            navigate(modalState.redirectRoute);
            dispatch(closeModal())
        }, 500)
    }
    useEffect(() => {
        console.log(modalState)
        if (modalState.isOpen) {
            callModal()
        }
    }, [modalState]);
    if (!modalState.isOpen) return null;

    return contextHolderModal;
};

export default ModalComponent;
